import {BankIdApi, UserApi} from '~/gen/openapi/portalService';
import {isFakeOrgNumber} from '~/helpers/organisationHelper';

/**
 * Creates a function to be used for checking if logged-in user is allowed to edit account details
 * @param userApi
 * @param bankidApi
 * @return function perform the check.
 */
export function getCanEditAccountChecker(userApi: UserApi, bankidApi: BankIdApi) {
	return async function canEditAccountDetails(orgNumber: string): Promise<{ canEdit: boolean, personThatCanEdit?: string, canEditBankAccountNumber: boolean }> {
		// Get signing combinations for the company.
		// If the company is of type ENK, and it is the owner of said ENK that is verifying,
		// they may make changes to the account details.
		try {
			// this user/contact info is stored in a httponly cookie.
			// api will extract it for us so we can use it for comparison
			const loggedInUserCrmContactId = (await userApi.userGetLoggedInUser()).data
			if (!orgNumber || isFakeOrgNumber(orgNumber)) {
				// Fake orgs cannot edit bankaccountnumber
				// https://dev.azure.com/GlommenMjosen/Portaler%20og%20SBL-app/_workitems/edit/1716
				return {canEdit: true, canEditBankAccountNumber: false}
			}
			const signingCombinations = await bankidApi.bankIdGetSigningCombinations(orgNumber)

			if (signingCombinations.data.OrgInformation?.OrganizationType !== "ENK") {
				// Only ENK companies can edit.
				return {canEdit: false, canEditBankAccountNumber: false}
			}

			if (signingCombinations.data.SigningCombinations?.length) {
				const theSigningCombination = signingCombinations.data.SigningCombinations[0]
				if (theSigningCombination.Persons?.length !== 1) {
					console.warn("Multiple signing combination persons for ENK with orgnumber " + orgNumber)
					return {canEdit: false, canEditBankAccountNumber: false}
				}

				// Is it the same user that has logged in that can edit the info?
				const thePersonThatCanSignOnBehalfOfTheCompany = theSigningCombination.Persons[0]
				if (thePersonThatCanSignOnBehalfOfTheCompany.ContactIdCrm && thePersonThatCanSignOnBehalfOfTheCompany.ContactIdCrm === loggedInUserCrmContactId) {
					// Same user! Can edit
					return {canEdit: true, personThatCanEdit: thePersonThatCanSignOnBehalfOfTheCompany.Name, canEditBankAccountNumber: true}
				} else {
					return {canEdit: false, personThatCanEdit: thePersonThatCanSignOnBehalfOfTheCompany.Name, canEditBankAccountNumber: false}
				}
			}
			return {canEdit: false, canEditBankAccountNumber: false}
		} catch (e) {
			console.warn('Could not get signing combinations', e)
			return {canEdit: false, canEditBankAccountNumber: false}
		}
	}
}
