import mailchecker from 'mailcheck'

mailchecker.defaultDomains.push(
	'eidsiva.no',
	'getmail.no',
	'glommen-mjosen.no',
	'online.no',
	'telenor.no',
	'stortinget.no'
)

export default function mailcheck(email) {
	const suggestions = []

	mailchecker.run({
		email,
		suggested: (suggestion) => {
			suggestions.push(suggestion)
		},
	})

	return suggestions
}
